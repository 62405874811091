import { reactive } from 'vue';
// import { AudioContext, OfflineAudioContext } from 'standardized-audio-context';

// Constants
const WEBSOCKET_URL = "wss://radio.nms.network/api/live/nowplaying/websocket";
const IMAGE_SIZES = '512x512';
const IMAGE_TYPE = 'image/png';
// Default metadata
export const defaultMedia = { 
  title: 'NMS.FM',
	artist: 'nms.network',
	artwork: [{
    src: 'https://radio.nms.network/static/uploads/album_art.1726829092.png',
    sizes: IMAGE_SIZES,
    type: IMAGE_TYPE
  }]
}

// WebSocket handling
let socket = null;

const initWebSocket = () => {
  if (socket && socket.readyState <= WebSocket.OPEN) {
    return;
  }

  socket = new WebSocket(WEBSOCKET_URL);
  console.log('socket is', socket);

  socket.onopen = () => onWebSocketOpen();
  socket.onmessage = (event) => handleWebSocketMessage(event);
  socket.onerror = (error) => console.error('WebSocket Error:', error);
  socket.onclose = () => {
    console.log('WebSocket is closed now');
    socket = null; // Reset socket on close
  };
};


const onWebSocketOpen = () => {
  const subscriptionMessage = JSON.stringify({
    "subs": { [state.stationPlaying.ws]: {} }
  });
  console.log("Sending subscription message:", subscriptionMessage);
  socket.send(subscriptionMessage);
};

const handleWebSocketMessage = (event) => {
  const data = JSON.parse(event.data);
  const np = data?.pub?.data?.np;

  if (np) {
    updateMediaSession(np);
    state.whatPlaying = {
      id:  np.now_playing.song.id,
    	title: np.now_playing.song.title,
    	artist: np.now_playing.song.artist,
    	artwork: np.now_playing.song.art,
    	new: true
    };
  }
};

const closeWebSocket = () => {
  return new Promise((resolve, reject) => {
    if (!socket) {
      resolve();
      return;
    }

    socket.onclose = () => {
      console.log('WebSocket is closed now');
      socket = null;
      resolve();
    };

    socket.close();
  });
};

// Media Session
const updateMediaSession = (np) => {
  if (!np.now_playing || !('mediaSession' in navigator)) return;

  console.log(np)

  navigator.mediaSession.metadata = new MediaMetadata({
    title: np.now_playing.song.title,
    artist: np.now_playing.song.artist,
    artwork: [{
      src: np.now_playing.song.art,
      sizes: IMAGE_SIZES,
      type: IMAGE_TYPE
    }]
  });

  navigator.mediaSession.setPositionState({
    duration: np.now_playing.duration,
    playbackRate: 1,
    position: np.now_playing.elapsed,
  });
};

const state = reactive({
  isPlaying: false,
  stationPlaying: {
    name: 'A-Side',
    url: 'https://radio.nms.network/listen/a-side/radio.mp3',
    ws: 'station:a-side'
  },
  whatPlaying: {
	  title: defaultMedia.title,
	  artist: defaultMedia.artist,
	  artwork: defaultMedia.artwork[0].src
	},
  showPlaying: false,
  showFullPlaying: false,
  hideFullPlaying: true,
  player: null,
  context: null,
  source: null,
  gain: null,
  muted: false,
  volume: 1,
  pauseCount: 0,
  playCount: 0,
  reactionsCount: 0,
  rotationAngle: 0,
  animationFrameId: null
});

// Singletons
let audioContext = null

const createContext = () => {
	if (typeof window === 'undefined') return null; 

	let audioContext = new AudioContext
	audioContext.resume();

	return audioContext;
}


const createPlayer = () => {
  if (typeof window === 'undefined') return null;

  const player = new Audio();

  player.crossOrigin = "anonymous";
  player.src = state.stationPlaying.url;
  player.controls = true;
  player.autoplay = false;
  player.muted = state.muted

  return player;
};

const createSource = (context, audio) => {
  if (!state.source) {
  	state.source = context.createMediaElementSource(audio);
	// state.source.connect(context.destination);
  }

  return state.source;
};

const getContextInstance = () => {
  if (!state.context) {
    state.context = createContext();
  }
  return state.context;
};

const getSourceInstance = (context, source) => {
  if (!state.source) {
    state.source = createSource(context, source);
  }
  return state.source;
};

const getPlayerInstance = () => {
  if (!state.player) {
    state.player = createPlayer();
  }
  return state.player;
};

// Функции управления плеером
const play = async () => {
	const player = getPlayerInstance();
  
  player.muted = state.muted
  player.src = state.stationPlaying.url;

	state.isPlaying = true;
	state.showPlaying = true;

  state.playCount += 1
    
	if (player && player.paused) {
    startRotation()
    await player.play();
    
  }

  if ('mediaSession' in navigator) {
		navigator.mediaSession.playbackState = 'playing'
  	if (!state.whatPlaying.new) navigator.mediaSession.metadata = new MediaMetadata(defaultMedia);
  }
};


const toggleMute = async () => {
  if (state.player) state.player.muted = !state.player.muted
  state.muted = !state.muted
};

const pause = async () => {
 	state.isPlaying = false;
   stopRotation()

	if (state.player && !state.player.paused) {
    state.player.pause();
   
  }

  if ('mediaSession' in navigator) {
		navigator.mediaSession.playbackState = 'paused'
  }

  state.pauseCount += 1
};

const changeStation = async (station) => {
  pause();
  await closeWebSocket();
  state.stationPlaying = station;
  initWebSocket();
  play();
};

const rotateDisk = (disk) => {
  if (state.isPlaying) {
    state.rotationAngle = (state.rotationAngle + 1) % 360; // Увеличиваем угол и обеспечиваем его возврат к 0 после 360
    
    const disk = disk; // Селектор вашего диска
    
    if (disk) {
      disk.style.transform = `rotate(${rotationAngle}deg)`;
    }
    state.animationFrameId = requestAnimationFrame(rotateDisk); // Планируем следующий кадр
  }
};

const startRotation = (disk) => {
  if (!state.animationFrameId) {
    rotateDisk(disk);
  }
};

const stopRotation = () => {
  if (state.animationFrameId) {
    cancelAnimationFrame(state.animationFrameId);
    state.animationFrameId = null;
  }
};


const hidePlayer = () => {
  state.showPlaying = false;
};

const toggleFullPlayer = () => {
  state.showFullPlaying = !state.showFullPlaying;
};


export {
  initWebSocket, 
  updateMediaSession,
  toggleFullPlayer,
  hidePlayer,
  state, 
  play, 
  pause,
  toggleMute,
  createContext,
  getContextInstance,
  createSource,
  getSourceInstance,
  createPlayer,
  getPlayerInstance,
  changeStation
};


