import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
} from 'vue-router';

import { defineAsyncComponent } from 'vue'

import { scrollBehavior, layoutClient, Header, Sidebar } from '@ozdao/prometheus-framework/modules/globals/client';

let Walkthrough;

if (__MOBILE_APP__) {
  Walkthrough = defineAsyncComponent(() =>
    import('@/components/sections/Walkthrough.vue').then(_ => _.default)
  );
} else {
  Walkthrough = null;
}

import Logotype from '@/components/icons/Logotype.vue'
import Navigation from '@/components/partials/Navigation.vue'
import RadioPopupWrapper from '@/components/partials/RadioPopupWrapper.vue'
import RadioPlayerWrapper from '@/components/partials/RadioPlayerWrapper.vue'

import auth from '@ozdao/prometheus-framework/modules/auth/client';

const isServer = typeof window === 'undefined';

let history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [
  {
   	path: '/:locale?',

    components: {
      default: layoutClient,
    },
    name: 'Home',

    meta: {
      navigation: Navigation,
      sidebar: Sidebar,
      header: {
        component: Header,
        logotype: Logotype,
        location: false
      },
      radio: {
        page: RadioPopupWrapper,
        player: RadioPlayerWrapper
      },
      walkthrough: Walkthrough,
    },

    beforeEnter: [
      ...(process.env.MOBILE_APP ? [auth.views.middlewares.validationAuth.initUser] : [])
    ],

    children: [
    	{
        path: '',
        name: 'Home Page',
        meta: {
          title_hide: true,
        },
        component: process.env.MOBILE_APP ? () => import(/* webpackChunkName: 'Home' */ '@/components/pages/HomeMobile.vue') : () => import(/* webpackChunkName: 'Home' */ '@/components/pages/Home.vue'),
      },{
        name: 'Radio',
        path: 'radio',
        meta: {
          header_theme: 'dark',
        },
        component: () => import(/* webpackChunkName: 'About' */ '@/components/partials/Radio/RadioFull.vue'),
      },{
        name: 'About',
        path: 'about',
        component: () => import(/* webpackChunkName: 'About' */ '@/components/pages/About.vue'),
      },{
        name: 'Contacts',
        path: 'contacts',
        component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/pages/Contacts.vue'),
      },
    ]
  }
];

export function _createRouter() {
  return createRouter({
    routes,
    history,
    scrollBehavior
  });
}