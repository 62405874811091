<template>
  <section 
    class="o-y-scroll o-x-hidden bg-black pd-small pos-relative z-index-2 t-center t-white w-100 h-100"
  >
    
    <div 
      class="pos-relative  gap-small flex-nowrap flex-column flex-center flex z-index-1"
    >
      <!-- COVER -->
      <div class="mn-b-thin h-max-30r br-2px br-solid br-white-transp-10 radius-extra w-100 pos-relative aspect-1x1 flex-center flex "
        style="
          background: linear-gradient(180deg, #BDBDBD 0%, #D9D9D9 79.69%, #A5A5A5 100%);
          box-shadow: 0 2px 0px 2px #aaaaaa;
        "
      >

       

        <img 
          class="pos-absolute pos-b-10 pos-r-10 h-4r w-4r z-index-2" 
          src="@/components/partials/Radio/brand.png"
        >

        <div class="pos-relative">
          <div class="radioTonarWrapper"  :class="{'activeTonar':radioStore.state.isPlaying}">
            <img 
              class="radioTonar z-index-2" 
              src="@/components/partials/Radio/tonar.png"
              :class="{'playingTonar':radioStore.state.isPlaying}"
            >
          </div>
          <div 
            ref="disk"
            :class="['radius-100 rotate-gradient flex-center flex']" 
            :style="{
              transform: `rotate(${radioStore.state.rotationAngle}deg)`,
              width: '24rem',
              height: '24rem',
              border: '3px solid var(--black)',
              'box-shadow': 'rgba(0,0,0,0.5) 0 0 64px 0px'
            }"
            class="pos-relative z-index-1"
          >
            <div
              v-for="i in 11"
              
              :style="{
                border: '1px solid rgba(0,0,0,0.25)',
                position: 'absolute',
                width: 23 - i * 1 + 'rem',
                height: 23 - i * 1 + 'rem',
                'border-radius': '100%',
                'box-shadow': 'rgb(47 47 47 / 18%) 0px 0px 5px 10px'
              }"
            >
            </div>

            <div
              :style="{
                width: '10rem',
                height: '10rem',
                backgroundImage: `url('${radioStore.state.whatPlaying.artwork}')`,
                backgroundPosition: 'center center',
                backgroundSize: '106%',
              }"
              class="radius-extra br-solid  br-white-transp-10 br-1px"
            >
            </div>
          </div>

        </div>

        <!-- <RadioVisualizer
          class="pos-absolute pos-t-0 pos-l-0 w-100 h-100 z-index-0"
        /> -->
      </div>

    <div 
      class="br-2px br-solid pd-regular br-white-transp-10 radius-big o-hidden w-100 pos-relative"
    >
      <div class="mn-b-thin w-100 gap-thin flex-nowrap flex">
        <IconEvents :fill="'rgb(var(--white))'" class="t-transp i-semi"/>
        <p class="w-100 uppercase t-transp t-left">Featured Stations</p>
      </div>

      <div class="w-100 mn-b-thin radius-big bg-main gap-thin flex-nowrap flex">
        <button 
          @click="radioStore.changeStation({
            name: 'A-Side',
            url: 'https://radio.nms.network/listen/a-side/radio.mp3',
            ws: 'station:a-side'
          })"
          class="pd-small w-100 radius-extra flex-center flex bg-red"
          :class="{
            'bg-white-transp-5 br-solid br-white-transp-20 br-1px': radioStore.state.stationPlaying.url !== 'https://radio.nms.network/listen/a-side/radio.mp3',
            'bg-second': radioStore.state.stationPlaying.url === 'https://radio.nms.network/listen/a-side/radio.mp3'
          }" 
        >
          A-Side
        </button>
        <button 
          @click="radioStore.changeStation({
            name: 'B-Side',
            url: 'https://radio.nms.network/listen/b-side/radio.mp3',
            ws: 'station:b-side'
          })"
          class="pd-small w-100 radius-extra flex-center flex bg-red"
          :class="{
            'bg-white-transp-5 br-solid br-white-transp-20 br-1px': radioStore.state.stationPlaying.url !== 'https://radio.nms.network/listen/b-side/radio.mp3',
            'bg-second': radioStore.state.stationPlaying.url === 'https://radio.nms.network/listen/b-side/radio.mp3'
          }" 
        >
          B-Side
        </button>
      </div>
    </div>


      
      <!-- <h4 class="w-100 t-left">NOW PLAYING</h4> -->
      <div 
        class="br-2px br-solid pd-small br-white-transp-10 radius-big o-hidden w-100 pos-relative"
      >
        <p class="mn-l-auto mn-b-thin mn-r-auto radius-big pd-l-thin pd-r-thin bg-second w-min">ON&nbsp;AIR</p>
        <!-- SONG -->
        <Marquee
          v-if="radioStore.state.whatPlaying" 
          class="h2 uppercase mn-b-thin"
          :gradient="true"
          :gradientColor="'var(--black)'"
          :clone="true"
          gradient-length="10%"
        >
          {{ radioStore.state.whatPlaying.title + '\xa0\xa0'}}
        </Marquee>
       
        <!-- AUTHOR -->
        <p 
          v-if="radioStore.state.whatPlaying" 
          class="h3 uppercase   t-transp"
        >
          {{ radioStore.state.whatPlaying.artist }}
        </p>
      </div>

     <!--  <RadioVolume
        class="br-2px br-solid br-white-transp-10 radius-big o-hidden w-100 pos-relative  pd-regular radius-big flex-center flex-nowrap flex gap-medium"
      /> -->

      <div 
        class="gap-big flex-center flex-nowrap flex br-2px br-solid pd-small br-white-transp-10 radius-big o-hidden w-100 pos-relative"
      >
        
        <div class="flex-nowrap flex gap-thin pos-relative">
          
          <IconLike
            @click="toggleReaction()"
            :isLiked="isReacted"
            fill="rgb(var(--white))"
            class="i-big"
            :class="{'no-events':isLoading}"

          />
          <p 
            class="t-semi h3 t-center mn-t-micro"
          >
            {{reactionsCount}}
          </p>
        </div>

        <button 
          @click="radioStore.state.isPlaying ? radioStore.pause() : radioStore.play()" 
          class="mn-r-medium mn-l-medium flex-center flex w-8r h-8r radius-extra"
          :class="{
            'bg-white-transp-5 br-solid br-white-transp-20 br-1px': !radioStore.state.isPlaying,
            'bg-second': radioStore.state.isPlaying
          }" 
        >
          <div 
            ref="player_button" 
            :class="{
              'paused': !radioStore.state.isPlaying
            }" 
            class="i-semi pauseplay-btn"
          >
            <div class="radius-small d1"></div>
            <div class="radius-small d2"></div>
          </div>
        </button>

        

        <IconMute
          v-if="radioStore.state.muted"
          fill="rgb(var(--white))"
          class="i-big"
          @click="radioStore.toggleMute()"
        />
        <IconUnMute
          v-if="!radioStore.state.muted"
          fill="rgb(var(--white))"
          class="i-big"
          @click="radioStore.toggleMute()"
        />
      </div>
      

       <div 
        v-if=" options.showSchedule"
        class="mn-b-small br-2px br-solid br-white-transp-10 radius-big o-hidden w-100 pos-relative radius-big  gap-medium"
      >

        <div class=" pd-regular  w-100 gap-thin flex-nowrap flex">
          <IconEvents :fill="'rgb(var(--white))'" class="t-transp i-semi"/>
          <p class="w-100 t-transp t-left">SCHEDULE</p>
          <!-- <h4 class="w-100 t-main t-right">SONG HISTORY</h4> -->
        </div>

        <div 
          v-if="stantion?.schedule"
          v-for="(track, index) in stantion?.schedule.slice(0, 10)" :key="index"
          class="br-t br-solid br-white-transp-20 w-100 pos-relative pd-regular flex-nowrap flex-v-center flex gap-small"
        >

          <span 
            class="w-max p-small mn-r-auto t-left p-big"
            :class="{'t-second':track.is_now}"
          >
            <Text 
              :text="track.title" 
              :maxLen="22"
              style=" white-space: nowrap;"
            />
          </span>

          <p 
            class="pd-l-thin pd-r-thin radius-big w-max t-right p-regular" 
            :class="{
              't-black bg-second':track.is_now,
              't-white bg-white-transp-15':!track.is_now
            }"
            style=" white-space: nowrap;">
              {{ formatDate(track.start) }}
              <!-- -{{ formatDate(track.end) }} -->
          </p>
          
        
        </div>

      </div>
        
    </div>

  </section>
</template>

<script setup>
import { ref, onMounted,watch  } from 'vue';
import axios from 'axios';

import { Text, Tooltip, Marquee } from '@ozdao/prometheus-framework'
import { IconMute,IconUnMute, IconLike, IconEvents }  from '@ozdao/prometheus-framework/modules/icons/client';

import RadioVisualizer      from '@/components/partials/Radio/RadioVisualizer.vue';
import RadioVolume      from '@/components/partials/Radio/RadioVolume.vue';

import * as radioStore from './radio.store.js';

import { reactionsStore }    from '@ozdao/prometheus-framework/modules/community/client';
import { authStore }    from '@ozdao/prometheus-framework/modules/auth/client';

const props = defineProps({
  options: {
    type: Object,
    default: {
      position: false,
      showSchedule: true
    }
  }
})

const stantion = ref([]);
const disk = ref(null);
const isReacted = ref(false)
const isLoading = ref(false)
const reactionId = ref(null)
const reactionsCount = ref(0)


async function loadStantion() {
  try {
    const response = await axios.get('https://radio.nms.network/api/station/1/profile',{
      headers: { Authorization: `Bearer 7846b66deff84c80:e9c5c7dd7fcb63bf8905a7e7b05551a8` }
  });
    stantion.value = response.data; // предполагается, что response.data - это массив треков
  } catch (error) {
    console.error('Error fetching schedule:', error);
  }
}

async function getReactions() {
  isLoading.value = true 

  try {
    let reactions = await reactionsStore.actions.read({
      type: 'mix', 
      targetString: radioStore.state.whatPlaying.title
    });

    // Проверка, есть ли реакция от текущего пользователя
    isReacted.value = reactions.some(reaction => {
      if (reaction.user === authStore.state.user._id) {
        reactionId.value = reaction._id; // Предполагая, что _id - это идентификатор реакции
        return true;
      }
      return false;
    });

    // Устанавливаем количество реакций
    reactionsCount.value = reactions.length;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    console.error("Ошибка при получении реакций:", error);
  }
}

async function addReaction(type) {
  isLoading.value = true 

  if (!authStore.state.user._id) {
    alert('Please login to add a reaction');
    return;
  }

  try {
    const reaction = {
      targetString: radioStore.state.whatPlaying.title,
      type: 'mix',
      user: authStore.state.user._id,
      class: 'like'
    };
    const res = await reactionsStore.actions.create(reaction);
    isLoading.value = false 

    if (res.reaction.class === 'like') {
      isReacted.value = true;
      reactionId.value = res.reaction._id;
      reactionsCount.value += 1;
    }
  } catch (err) {
    isLoading.value = false 
    console.error(err);
    alert('Error adding reaction. Please try again later.');
  }
}

async function deleteReaction(type) {
  isLoading.value = true

  if (!authStore.state.user._id) {
    alert('Please login to add a reaction');
    return;
  }

  try {

    const reaction = { _id: reactionId.value };

    await reactionsStore.actions.delete(reaction);
    isLoading.value = false 

    isReacted.value = false;
    reactionId.value = null;
    reactionsCount.value -= 1;
  } catch (err) {
    isLoading.value = false 
    alert('Error adding reaction. Please try again later.');
  }
}

async function toggleReaction() {
  isReacted.value !== true ? await addReaction () : await deleteReaction ()
}


onMounted(async() => {
  radioStore.initWebSocket();

  navigator.mediaSession.setActionHandler('pause', await radioStore.pause);
  navigator.mediaSession.setActionHandler('play', await radioStore.play);
  navigator.mediaSession.setActionHandler('stop', await radioStore.pause);

  await loadStantion();
  await getReactions();
});

watch(() => radioStore.state.whatPlaying.title, async (newTitle, oldTitle) => {
  if (newTitle !== oldTitle) { 
    isReacted.value = false;
    reactionId.value = null;
    reactionsCount.value = 0;

    await getReactions(); 
  }
});

</script>


<style lang="scss">
.radioTonarWrapper {
    width: 15rem;
    position: absolute;
    z-index: 30;
    transform: rotate(253deg);
    transform-origin: 75% center;
    top: 0;
    right: -2.5rem;
    transition: transform 1s ease-in-out;
}
.radioTonar {
  width: 15rem;
    transform-origin: 75% center;
   /* Убедитесь, что transition применяется только к transform */
}

.activeTonar {
    transform: rotate(285deg);
}

.playingTonar {
    animation: playingTonar 2s ease-in-out infinite;
}

@keyframes playingTonar {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(-2deg);
    }
}

</style>


